import React from "react"
import Layout from "../../components/layout"
import PcpHero from "../../components/PcpHero"
import Breadcrumbs from "../../components/Breadcrumbs"
import VideoModule from "../../components/VideoModule"
import HowHubbleWorksCarousel from "../../components/HowHubbleWorksCarousel"
import SEO from "../../components/seo"
import avgProducts from "../../services/avg-products.json"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "../../styles/pages/hubble-lenses.scss"

import PcpList, { formatPcpItem } from "../../components/PcpList"

const BaushLombPcp = ()  => {
  const schemaMarkup = [
    {
      "@context": "https://schema.org",
      "@type": "VideoObject",
      "name": "Hubble Contacts | How We Make Contact Lenses Easy",
      "description": "At Hubble, we believe buying contact lenses should be easy. That’s why we’ve made Hubble’s personalized subscription service the easiest contacts experience in the world.",
      "thumbnailUrl": "https://i.ytimg.com/vi/w4YCLxsUxyI/hqdefault.jpg",
      "uploadDate": "2023-03-20",
      "duration": "PT1M15S",
      "contentUrl": "https://www.hubblecontacts.com",
      "embedUrl": "https://www.youtube.com/embed/w4YCLxsUxyI"

    },
    {
      "@context": "https://schema.org",
      "@url": "https://hubblecontacts.com/contact-lenses/",
      "@type": "webpage",
      "name": "Hubble Contacts: Order Prescription Contacts Online and Personalize Your Subscription",
      "description": "Order contact lenses online easily with Hubble. Our high-quality and affordable prescription contact lenses get delivered directly to your door and always ship free. Try Hubble’s classic or Hydro contact lenses",
      "publisher": {
        "@type": "Organization",
        "name": "Hubble"
      }
    }
  ]

  let products = []
  avgProducts.forEach((product, index) => {
    product.options.forEach((option, optionIndex) => {
      const title = option?.title?.replace(/6pk|12pk|24pk|30pk|90pk/, '')?.trim()
      const isValid = title.toLocaleLowerCase().includes('bausch')
      if (isValid) {
        const pcpItem = formatPcpItem(product, option, optionIndex)
        products.push(pcpItem)
      }
    })
  })

  return (
    <Layout>
      <SEO
        title="Hubble Contacts: Order Prescription Contacts Online and Personalize Your Subscription"
        description="Order contact lenses online easily with Hubble. Our high-quality and affordable prescription contact lenses get delivered directly to your door. Try Hubble’s classic, Hydro, and SkyHy contact lenses."
        schemaMarkup={schemaMarkup}
      />
      <PcpHero
        heroText={<>Bausch + Lomb Ultra</>}
        heroImage={"none"}
        background="purple"
        bannerClass="purple promo-banner pcp"
        bannerText="Start your subscription now for <strong>35% OFF</strong> your first order."
        shopNowInBanner
      />
      <div className="pcp-container">
        <Breadcrumbs links={[{ to: "/", label: "Homepage" }, { label: "Bausch + Lomb Ultra" }]} />
        <PcpList {...{ products }} />
      </div>
      <HowHubbleWorksCarousel color="purple"/>
      <div className="video-container">
        <VideoModule
          type={"contacts"}
          video={"https://www.youtube.com/embed/NKxNYzCRGwg"}
          image="Pages/ContactsPDP/Hubble-Contacts-PDP-Video-Still.jpg"
          alt_text="Woman unboxing a new delivery of Hubble Contacts"
        />
      </div>
      <div className="pt-5 mt-0 mt-sm-5"></div>
    </Layout>
  )
}

export default BaushLombPcp
